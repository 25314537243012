import React, { useEffect } from "react";
import { Container, Row, Col, Button, CardDeck } from "react-bootstrap";
import { Link } from "gatsby";
import HeaderExpanded from "../components/HeaderExpanded/HeaderExpanded";
import IntegrationCard from "../components/IntegrationCard/IntegrationCard";
import "../styles/spraylog.scss";
import { Helmet } from "react-helmet";

function SprayLog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | HortPlus SprayLog</title>
        <meta
          name="description"
          content="HortPlus SprayLog is a simple-to-use web-based spray diary
              programme designed to make the recording of agrichemical spray
              applications easy"
        />
      </Helmet>
      <HeaderExpanded title={"HortPlus SprayLog"} />
      <Container className="my-5">
        <Row>
          <Col md={6} className="d-flex flex-column justify-self-center">
            <p>
              HortPlus SprayLog is a simple-to-use web-based spray diary
              programme designed to make the recording of agrichemical spray
              applications easy. It can handle multiple properties over multiple
              seasons while keeping all your spray diary records in one place.
              With it's super simple block / spray framework, HortPlus SprayLog
              works across all sectors of the horticultural industry and
              integrates with the HortPlus MetWatch Platform.
            </p>
            <p>
              You can record what was sprayed where (block), when (date), why
              (target pest / weed / disease), who (operator / growsafe
              certification), how much was used (rate). Share your records by
              printing or emailing them, or export them to an Excel spreadsheet.
            </p>
            <Button
              className="my-3"
              as={Link}
              to={"https://spraylog.sprayview.nz/"}
            >
              Go to SprayLog
            </Button>
          </Col>
          <Col md={6}>
            <h6 className="hp-font-28">Features</h6>
            <ul>
              <li>Simple and easy-to-use interface</li>
              <li>Keep all spray diary records in one place</li>
              <li>Works across multiple properties and years</li>
              <li>Share your diaries with others</li>
              <li>Comply with industry bodies and standards</li>
              <li>Runs on any computer or device</li>
              <li>All NZ registered products included (from ACVM Register)</li>
              <li>Many supplementary products included</li>
              <li>All crops and varieties included</li>
              <li>Industry standard varieties / codes used</li>
              <li>Simple block / spray framework</li>
              <li>Works across all horticultural sectors</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid className="hp-page hp-page--blue-grey">
        <Container fluid className="d-flex flex-column align-items-center">
          <CardDeck className="hp-carddeck-width justify-content-center">
            <IntegrationCard type={"sprayview"} />
            <IntegrationCard type={"traplog"} />
            <IntegrationCard type={"sprayplanmanager"} />
            <IntegrationCard type={"spraylog"} />
          </CardDeck>
        </Container>
      </Container>
    </>
  );
}

export default SprayLog;
